<template>
  <nav
    v-show="visibleHeader == 'true'"
    class="navbar navbar-expand-lg navbar-light fixed-top header-inside mb-5 border-bottom p-0"
  >
    <div class="container-fluid header-viewer">
      <div class="div-logo">
        <a href="https://copla.io" target="_blank"
          ><img
            src="@/assets/images/logo_claro.png"
            alt="Logo de Copla"
            class="logo"
        /></a>
      </div>
      <h1 class="d-none d-xl-block">{{ event }}</h1>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse float-end mt-3 mt-lg-0"
        id="navbarCollapse"
      >
        <button
          type="button"
          :disabled="button.disabled"
          :class="button.class"
          id="btn-connection"
          class="btn me-4 opacity"
        >
          {{ button.title }}
        </button>
        <label
          for="backgroundColor"
          class="col-form-label col-form-label-sm me-2 me-sm-1"
          v-if="!isAD"
          >{{ $t('Background') }}</label
        >
        <input
          type="color"
          class="form-control-sm me-sm-2 color-picker"
          id="backgroundColor"
          value="#000000"
          title="Fondo"
          v-if="!isAD"
          v-on:change="changeBackgroundColor"
          v-model="backgroundColor"
        />
        <label
          for="backgroundColor"
          class="col-form-label col-form-label-sm me-1 ms-1"
          v-if="!isAD"
          >{{ $t('Color') }}</label
        >
        <input
          type="color"
          class="form-control-sm color-picker"
          id="textColor"
          value="#ffffff"
          title="Texto"
          v-if="!isAD"
          v-on:change="changeColor"
          v-model="color"
        />
        <select
          class="form-select form-select-sm ms-3"
          aria-label="Default select example"
          style="width: 140px"
          v-if="!isAD"
          v-on:change="changeFontFamily"
          v-model="fontFamily"
        >
          <option value="Arial, sans-serif">Arial</option>
          <option value="'Brush Script MT', cursive">Brush Script MT</option>
          <option value="'Courier New',monospace">Courier New</option>
          <option value="Garamond, serif">Garamond</option>
          <option value="Georgia, serif">Georgia</option>
          <option value="Helvetica, sans-serif">Helvetica</option>
          <option value="Roboto, sans-serif">Roboto</option>
          <option value="Tahoma, sans-serif">Tahoma</option>
          <option value="'Times New Roman', serif">Times New Roman</option>
          <option value="'Trebuchet MS', sans-serif">Trebuchet</option>
          <option value="Verdana, sans-serif" selected>Verdana</option>
        </select>
        <select
          class="form-select form-select-sm ms-3"
          aria-label="Default select example"
          style="width: 100px"
          v-if="!isAD"
          v-on:change="changeFontSize"
          v-model="fontSize"
        >
          <option value="14">14</option>
          <option value="18">18</option>
          <option value="24">24</option>
          <option value="30">30</option>
          <option value="36">36</option>
          <option value="42">42</option>
          <option value="48" selected>48</option>
          <option value="54">54</option>
          <option value="60">60</option>
          <option value="72">72</option>
          <option value="84">84</option>
          <option value="96">96</option>
          <option value="120">120</option>
          <option value="150">150</option>
          <option value="180">180</option>
          <option value="200">200</option>
          <option value="250">250</option>
        </select>
        <button
          type="button"
          id="btn-customURL"
          class="btn btn-info ms-3 opacity"
          v-if="!isAD"
          @click="urlCustom"
        >
          {{ customURLButton }}
        </button>
        <input
          v-if="isAD"
          type="checkbox"
          id="showAD"
          v-model="showAD"
          class="checkbox"
        /><label v-if="isAD" for="showAD"> {{ $t('ShowADText') }}</label>
      </div>
    </div>
  </nav>
  <modal-activate-audio
    title="Audiodescripción"
    :body="$t('ADAdvise')"
    :button="$t('StartAD')"
    :visible="showModal"
    @confirm="confirm"
  />
  <main
    aria-live="polite"
    class="p-4 viewer viewerDiv"
    :style="{
      color: color,
      backgroundColor: backgroundColor,
      fontFamily: fontFamily,
      fontSize: fontSize + 'px'
    }"
    id="transcription"
    ref="$transcription"
  >
    {{ transcription.text }}
    <div v-html="htmlText" :class="[typeViewer]"></div>
  </main>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
export default {
  components: { ModalActivateAudio },
  name: 'Home-Viewer'
}
</script>

<script setup>
import useSession from '@/hooks/useSession'
import { useI18n } from 'vue-i18n'
import ModalActivateAudio from '@/components/ModalActivateAudio.vue'
import messages from '@/lang'

//USES && VARS
var backgroundColor = ref('#000000')
const baseURL = process.env.VUE_APP_BASE_URL
const session = useSession(baseURL)
const i18n = useI18n({})
const route = useRoute()
var button = reactive({
  title: i18n.t('Disconnected'),
  disabled: true,
  class: 'btn-danger'
})
var color = ref('#FFFFFF')
var customURLButton = ref(i18n.t('CustomURL'))
var event = ref('')
var fontFamily = ref('Verdana, sans-serif')
var fontSize = ref('48')
var htmlText = ref('')
var isAD = ref(false)
var languages = messages.es.Languages
var lastMsg = ref(-1)
var lastStartit = ref(-1)
var lastSync = ref(-1)
var prevText = ref('')
var showAD = ref(false)
var showModal = ref(false)
var space = ref(' ')
var transcription = ref({ text: '', prev: '' })
const $transcription = ref(null)
var typePrev = ref('')
var typeViewer = ref('')
var wss = null
var visibleHeader = ref('true')

//LIFECYCLE
onMounted(() => {
  getBrowserLanguage()
  readParameters()
  record()
})

//FUNCTIONS
const changeBackgroundColorNotification = val => {
  backgroundColor.value = val
}
const changeColorNotification = val => {
  color.value = val
}
const changeFontSize = val => {
  fontSize.value = val.srcElement.value
}
const changeFontSizeQuery = val => {
  fontSize.value = val
}
const changeFontFamily = val => {
  fontFamily.value = val.srcElement.value
}
const changeFontFamilyQuery = val => {
  fontFamily.value = val
}
const changeColor = val => {
  color.value = val.srcElement.value
}
const changeColorQuery = val => {
  color.value = val
}
const changeBackgroundColor = val => {
  backgroundColor.value = val.srcElement.value
}
const changeBackgroundColorQuery = val => {
  backgroundColor.value = val
}
const changeHeaderVisible = val => {
  visibleHeader.value = val
}
const confirm = () => {
  showModal.value = false
}
const getBrowserLanguage = () => {
  let language = navigator.language || navigator.userLanguage
  let locale = language.split('-')
  if (locale[1] !== undefined && locale[1] == 'ct') {
    locale[0] = 'ca'
  }
  switch (locale[0]) {
    case 'es':
    case 'en':
    case 'fr':
    case 'it':
    case 'de':
    case 'ca':
      i18n.locale.value = locale[0]
      break
    default:
      i18n.locale.value = 'en'
      break
  }
}
const isXML = data => {
  if (data.includes('<Paso')) {
    return true
  } else {
    return false
  }
}
const readParameters = () => {
  if (route.query.bg !== undefined) {
    changeBackgroundColorQuery('#' + route.query.bg)
  }
  if (route.query.fg !== undefined) {
    changeColorQuery('#' + route.query.fg)
  }
  if (route.query.font !== undefined) {
    changeFontFamilyQuery(route.query.font)
  }
  if (route.query.size !== undefined) {
    changeFontSizeQuery(route.query.size)
  }
  if (route.query.visible !== undefined) {
    changeHeaderVisible(route.query.visible)
  }
}
const replaceN = text => {
  return text.replace(/\n/g, '<br />')
}

const parseText = (data, type, current, code) => {
  let value = { text: '', prev: '' }
  let parser = new DOMParser()
  let newTxt = ''
  if (typePrev.value != type) {
    prevText.value = ''
    htmlText.value = ''
    current.prev = ''
  }
  typePrev.value = type
  let add = ''
  let limit = lastMsg.value + 3000
  if (Date.now() > limit) {
    add = '<br />'
    space.value = ''
  }
  lastMsg.value = Date.now()
  switch (type) {
    case 'notification':
      value = { text: data.text, prev: data.text }
      changeBackgroundColorNotification(data.bgcolor)
      changeColorNotification(data.color)
      break
    case 'stenotype':
      newTxt =
        prevText.value.substring(0, prevText.value.length - data.erase) +
        data.text
      prevText.value = newTxt
      htmlText.value = '<span>' + replaceN(newTxt) + '</span>'
      break
    case 'translation':
    case 'recognition':
      if (data.type == 'partial') {
        if (current['prev'] != '') {
          current.prev += add
          htmlText.value =
            '<span>' +
            current.prev +
            space.value +
            upperFirst(transformText(data.text)) +
            '</span>'
          value.prev = current.prev
        } else {
          htmlText.value =
            '<span>' + upperFirst(transformText(data.text)) + '</span>'
          value.prev = current.prev
        }
      } else {
        htmlText.value =
          '<span>' +
          (current.prev + space.value + transformText(data.text)).trim() +
          '</span>'
        value.prev = (
          current.prev +
          space.value +
          transformText(data.text)
        ).trim()
        space.value = ' '
      }
      break
    case 'startit':
      if (isXML(data)) {
        var xmlDoc = parser.parseFromString(data, 'text/xml')
        var step = xmlDoc.getElementsByTagName('Paso')
        var index = step[0].getAttribute('indice')
        var lines = xmlDoc.getElementsByTagName('Linea')
        var completeText = ''
        for (var line of lines) {
          var color = line.getAttribute('color')
          if (line.childNodes[0] !== undefined) {
            newTxt +=
              "<span style='color: " +
              color +
              "'>" +
              line.childNodes[0].nodeValue +
              '</span><br />'
            completeText += line.childNodes[0].nodeValue
          }
        }
        if (
          (completeText.trim() == '' && index == lastStartit.value) ||
          completeText.trim() != '' ||
          index == -1
        ) {
          htmlText.value = newTxt
          lastStartit.value = index
        }
      } else {
        let ad = JSON.parse(data)
        if (ad.file != '-1') {
          let file =
            'https://parser.' +
            baseURL +
            '/Audios/' +
            code.slice(0, -3) +
            '/' +
            ad.file
          var audio = new Audio(file)
          audio.play()
          if (showAD.value) {
            htmlText.value =
              "<span style='font-style:italic;'>(AD) " + ad.text + '</span>'
          }
        } else {
          if (isAD.value) {
            htmlText.value = ''
          }
        }
      }
      break
    case 'sync':
      if (data.file !== undefined) {
        if (data.file != '-1') {
          let file =
            'https://parser.' +
            baseURL +
            '/Audios/' +
            code.slice(0, -3) +
            '/' +
            data.file
          var audioSync = new Audio(file)
          audioSync.play()
          if (showAD.value) {
            htmlText.value =
              "<span style='font-style:italic;'>(AD) " + data.text + '</span>'
          }
        } else {
          if (isAD.value) {
            htmlText.value = ''
          }
        }
      } else {
        if (lastSync.value != data.index) {
          var subs = data.text
          var response = ''
          console.log(data)
          if (subs !== undefined && subs !== '') {
            console.log(subs)
            subs.forEach((sub, index) => {
              if (index != subs.length - 1) {
                response += sub + '<br />'
              } else {
                response += sub
              }
            })
            htmlText.value = response
            lastSync.value = data.index
          }
        }
      }
      break
    case 'erase':
      value = { text: '', prev: '' }
      break
    default:
      value = { text: data.text, prev: data.text }
      break
  }
  return value
}
const record = async () => {
  var code = route.params.code
  let codeWs = code
  if (code.slice(-3) == '_AD') {
    isAD.value = true
    showModal.value = true
  }
  let transLanguage = ''
  var language = route.params.language
  if (code == 'demo') {
    socket('demo', 'Writer')
  } else {
    if (code.indexOf('_') > -1) {
      let split = code.split('_')
      code = split[0]
      transLanguage = split[1]
    }
    let response = await session.getViewerInput(code)
    if (response.error) {
      transcription.value.text = i18n.t('CheckError')
    } else {
      if (transLanguage == 'AD') {
        transLanguage = 'Audiodescripción'
      }
      if (transLanguage != '') {
        event.value = response.data.session.name + ' - ' + transLanguage
      } else if (language == '') {
        event.value =
          response.data.session.name +
          ' - ' +
          languages[response.data.language].split('(')[0].trim()
      } else {
        event.value =
          response.data.session.name +
          ' - ' +
          languages[language].split('(')[0].trim()
        codeWs = code + '_' + language
      }

      document.title = 'Copla.io | ' + event.value
      socket(codeWs, response.data.type)
    }
  }
}
const socket = (code, type) => {
  wss = new WebSocket('wss://live.' + baseURL + '/listen')
  wss.onopen = () => {
    button.title = i18n.t('Connected')
    button.class = 'btn-success'
    var send = {
      type: 'listen',
      data: {
        code: code
      }
    }
    wss.send(JSON.stringify(send))
  }
  wss.onmessage = e => {
    if (
      JSON.parse(e.data).type == 'startit' ||
      JSON.parse(e.data).type == 'sync'
    ) {
      typeViewer.value = 'viewer-center'
    } else {
      typeViewer.value = ''
    }
    let textParser = parseText(
      JSON.parse(e.data).data,
      JSON.parse(e.data).type,
      transcription.value,
      code
    )
    transcription.value.text = textParser.text
    transcription.value.prev = textParser.prev
  }
  wss.onerror = e => {
    console.log(e)
  }
  wss.onclose = e => {
    button.title = i18n.t('Disconnected')
    button.class = 'btn-danger'
    record()
  }
}
const transformText = text => {
  let textTransform = text
  let transform = new Map()
  transform.set('YYYY', 'y')
  transform.set('YYY', 'y')
  transform.set('YY', 'y')
  transform.set('YE', 'e')
  transform.set('AAAA', 'a')
  transform.set('AAA', 'a')
  transform.set('AA', 'a')
  let keys = Array.from(transform.keys())
  keys.forEach(value => {
    let expReg = new RegExp(value, 'g')
    textTransform = textTransform.replace(expReg, transform.get(value))
  })
  return textTransform
}
const upperFirst = text => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}
const urlCustom = () => {
  const urlWithoutParams = route.path
  const protocol = window.location.protocol
  const host = window.location.host
  const mainUrl = `${protocol}//${host}${urlWithoutParams}`
  let customURL = mainUrl + '?'
  customURL += 'bg=' + backgroundColor.value.replace(/#/g, '') + '&'
  customURL += 'fg=' + color.value.replace(/#/g, '') + '&'
  customURL += 'font=' + fontFamily.value + '&'
  customURL += 'size=' + fontSize.value
  navigator.clipboard.writeText(customURL)
}
</script>

<style lang="scss" scoped>
@import '~@/styles/_variables.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto');

.form-select {
  display: inline-block;
}
.viewer {
  text-align: left;
  min-height: calc(100vh);
  padding-top: 60px !important;
}

.viewerDiv {
  height: 2.8em;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  /*white-space: pre-wrap;*/
}
.color-picker {
  width: 60px;
}
.navbar-collapse {
  flex-grow: 0 !important;
}
.header-inside {
  background-color: $copla-blue-grey-light;
  color: white;
}
h1 {
  margin: 0;
  font-size: 1.5em;
}
.logo {
  height: 35px;
  display: flex;
}

.header-viewer {
  padding-top: 8px;
  padding-bottom: 8px;
}

.navbar-collapse {
  text-align: center;
}

.col-form-label {
  vertical-align: super;
}

.form-select {
  vertical-align: top;
}

.opacity {
  opacity: unset;
}

@media only screen and (max-width: 991px) {
  #btn-connection {
    margin-bottom: 10px;
  }

  #btn-customURL {
    margin-top: 10px;
  }
}

.viewer-center {
  text-align: center;
}

.checkbox {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
</style>
