<template>
  <nav
    v-show="visibleHeader == 'true'"
    class="navbar navbar-expand-lg navbar-light fixed-top header-inside mb-5 border-bottom p-0"
  >
    <div class="container-fluid header-viewer">
      <div class="div-logo">
        <a href="https://copla.io" target="_blank"
          ><img
            src="@/assets/images/logo_claro.png"
            alt="Logo de Copla"
            class="logo"
        /></a>
      </div>
      <h1 class="d-none d-xl-block">{{ event }}</h1>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse float-end mt-3 mt-lg-0"
        id="navbarCollapse"
      >
        <button
          type="button"
          :disabled="button.disabled"
          :class="button.class"
          id="btn-connection"
          class="btn me-4 opacity"
        >
          {{ button.title }}
        </button>
      </div>
    </div>
  </nav>
  <modal-activate-audio
    title="Reproductor de audio"
    :body="$t('AudioAdvise')"
    :button="$t('StartAudio')"
    :visible="showModal"
    @confirm="confirm"
  />
  <main
    aria-live="polite"
    class="p-4 viewer viewerDiv"
    :style="{
      color: color,
      backgroundColor: backgroundColor,
      fontFamily: fontFamily,
      fontSize: fontSize + 'px'
    }"
    id="transcription"
    ref="$transcription"
  >
    {{ transcription.text }}
    <div v-html="htmlText" :class="[typeViewer]"></div>
  </main>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
export default {
  components: { ModalActivateAudio },
  name: 'Home-Audio'
}
</script>

<script setup>
import useSession from '@/hooks/useSession'
import { useI18n } from 'vue-i18n'
import ModalActivateAudio from '@/components/ModalActivateAudio.vue'

//USES && VARS
const baseURL = process.env.VUE_APP_BASE_URL
const session = useSession(baseURL)
const i18n = useI18n({})
const route = useRoute()
var button = reactive({
  title: i18n.t('Disconnected'),
  disabled: true,
  class: 'btn-danger'
})
var color = ref('#FFFFFF')
var backgroundColor = ref('#000000')
var event = ref('')
var fontFamily = ref('Verdana, sans-serif')
var fontSize = ref('48')
var htmlText = ref('')
var transcription = ref({ text: '', prev: '' })
const $transcription = ref(null)
var typeViewer = ref('')
var wss = null
var visibleHeader = ref('true')
const audioContext = new (window.AudioContext || window.webkitAudioContext)()
const nextStartTime = ref(audioContext.currentTime)
const audioQueue = ref([])
var showModal = ref(true)

//LIFECYCLE
onMounted(() => {
  getBrowserLanguage()
  record()
})

const confirm = () => {
  showModal.value = false
}

const getBrowserLanguage = () => {
  let language = navigator.language || navigator.userLanguage
  let locale = language.split('-')
  if (locale[1] !== undefined && locale[1] == 'ct') {
    locale[0] = 'ca'
  }
  switch (locale[0]) {
    case 'es':
    case 'en':
    case 'fr':
    case 'it':
    case 'de':
    case 'ca':
      i18n.locale.value = locale[0]
      break
    default:
      i18n.locale.value = 'en'
      break
  }
}

const record = async () => {
  var code = route.params.code
  let codeWs = code
  let response = await session.getViewerInput(code)
  if (response.error) {
    transcription.value.text = i18n.t('CheckError')
  } else {
    event.value = response.data.session.name
  }
  document.title = 'Copla.io | ' + event.value
  socket(codeWs, response.data.type)
}

const socket = (code, type) => {
  wss = new WebSocket('wss://live.' + baseURL + '/audio')
  wss.binaryType = 'arraybuffer'
  wss.onopen = () => {
    button.title = i18n.t('Connected')
    button.class = 'btn-success'
    var send = {
      type: 'audio',
      data: {
        code: code
      }
    }
    wss.send(JSON.stringify(send))
  }
  wss.onmessage = async e => {
    const audioData = e.data
    audioQueue.value.push(audioData) // Guarda el fragmento en la cola
    playAudio()
  }
  wss.onerror = e => {
    console.log(e)
  }
  wss.onclose = e => {
    button.title = i18n.t('Disconnected')
    button.class = 'btn-danger'
    record()
  }
}

const playAudio = () => {
  if (audioQueue.value.length > 0) {
    const audioData = audioQueue.value.shift(); 
    audioContext.decodeAudioData(audioData, (buffer) => {
      const source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);
      const now = audioContext.currentTime;
      if (nextStartTime.value < now) {
        nextStartTime.value = now;
      }
      source.start(nextStartTime.value);
      nextStartTime.value += buffer.duration;
    }, (error) => {
      console.error("Error al decodificar el audio:", error);
    });
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/_variables.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto');

.form-select {
  display: inline-block;
}
.viewer {
  text-align: left;
  min-height: calc(100vh);
  padding-top: 60px !important;
}

.viewerDiv {
  height: 2.8em;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  /*white-space: pre-wrap;*/
}
.color-picker {
  width: 60px;
}
.navbar-collapse {
  flex-grow: 0 !important;
}
.header-inside {
  background-color: $copla-blue-grey-light;
  color: white;
}
h1 {
  margin: 0;
  font-size: 1.5em;
}
.logo {
  height: 35px;
  display: flex;
}

.header-viewer {
  padding-top: 8px;
  padding-bottom: 8px;
}

.navbar-collapse {
  text-align: center;
}

.col-form-label {
  vertical-align: super;
}

.form-select {
  vertical-align: top;
}

.opacity {
  opacity: unset;
}

@media only screen and (max-width: 991px) {
  #btn-connection {
    margin-bottom: 10px;
  }

  #btn-customURL {
    margin-top: 10px;
  }
}

.viewer-center {
  text-align: center;
}

.checkbox {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
</style>
