import { createRouter, createWebHistory } from 'vue-router'
import Viewer from '../views/Viewer.vue'
import Audio from '../views/Audio.vue'

const routes = [
  {
    path: '/',
    redirect: '/demo'
  },
  {
    path: '/:code',
    name: 'viewer',
    component: Viewer,
    meta: {
      title: 'Copla.io'
    }
  },
  {
    path: '/audio/:code',
    name: 'audio',
    component: Audio,
    meta: {
      title: 'Copla.io'
    }
  },
  {
    path: '/:code/:language?',
    name: 'viewer',
    component: Viewer,
    meta: {
      title: 'Copla.io'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  document.title = to.meta.title || 'Copla.io';
});

export default router
